import siteInfo from "@/constants/siteInfo";
import Head from "next/head";

const OrganisationSchema = () => {
  return (
    <Head>
      <script
        id="OrganizationSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            name: siteInfo?.siteName,
            url: process.env.NEXT_PUBLIC_WEBSITE_URL,
            logo: `${process.env.NEXT_PUBLIC_WEBSITE_URL}${siteInfo?.siteLogo}`,
            address: {
              "@type": "PostalAddress",
              streetAddress: "FC8, Film City, Sector 16A Uttar Pradesh",
              addressLocality: "Noida",
              addressRegion: "Uttar Pradesh",
              postalCode: "201301",
              addressCountry: "India",
              telephone: "+91120-4807100",
            },
            sameAs: [
              siteInfo.socialLinks.facebook,
              siteInfo.socialLinks.youtube,
              siteInfo.socialLinks.twitter,
              siteInfo.socialLinks.instagram,
            ],
          }),
        }}
      />
    </Head>
  );
};

export default OrganisationSchema;
